import { useRemoveLastCharacter } from "../hooks/useRemoveLastCharacter";
import { Button } from "../components/Button";
import { StarsCanvas } from "../components/StarBackground";
import { useState } from "react";

export const Hero = ({ contactRef }: any) => {
  const titles = [
    "Software Engineer",
    "Game Developer",
    "Fullstack Developer",
    "Graphics Progammer",
  ];

  const { modifiedName } = useRemoveLastCharacter({ names: titles });

  const [skybox, setSkybox] = useState(true);
  return (
    <>
      <div className="w-full h-screen flex flex-col bg-black">
        <div className="absolute z-40 w-full">
          <div className="flex flex-row px-4 md:px-10">
            <button
              onClick={() => {
                setSkybox(!skybox);
              }}
            >
              Toggle Skybox
            </button>
            <div className="px-2">{`${skybox ? "☑️" : " "}`}</div>
          </div>
          <div className="flex w-full h-screen justify-center items-center text-center">
            <div className="px-2 flex flex-col items-center">
              <h1 className="text-4xl font-bold md:text-5xl">
                Welcome to my Portfolio
              </h1>
              <p className="text-gray-300 text-xl lg:text-3xl">
                My name is Reece Carey
              </p>

              <p className="text-gray-300 text-xl pb-4 lg:text-xl">
                {modifiedName}|
              </p>
              <div className="flex flex-col w-2/3 md:w-2/4 h-24">
                <Button
                  text={"Contact me"}
                  onClick={() => {
                    contactRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                />
                <a href={"./assets/reece-carey-cv.pdf"} target="_blank">
                  <Button
                    text={"Cirriculum Vitae"}
                    primary={false}
                    onClick={() => {}}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
        {skybox ? (
          <StarsCanvas />
        ) : (
          <video
            autoPlay
            loop
            muted
            className="absolute w-full h-full object-cover filter brightness-50 visible sm:visible mb-2"
          >
            <source src="/assets/HeroDemo.mp4" type="video/mp4" />
          </video>
        )}
      </div>
    </>
  );
};

export default Hero;
