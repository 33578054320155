import "./App.css";
import NavBar from "./sections/Navbar";
import Hero from "./sections/Hero";
import { AboutMe } from "./sections/AboutMe";
import { Projects } from "./sections/Projects";
import { Contact } from "./sections/Contact";
import { useRef } from "react";

function App() {
  const contactRef = useRef(null);
  const projectRef = useRef(null);
  const bioRef = useRef(null);

  return (
    <div className="w-full h-full text-gray-300 bg-gray-800">
      <NavBar
        showcase={false}
        bioRef={bioRef}
        projectRef={projectRef}
        contactRef={contactRef}
      />
      <Hero contactRef={contactRef} />
      <div ref={bioRef}>
        <AboutMe />
      </div>
      <div ref={projectRef}>
        <Projects />
      </div>
      <div ref={contactRef}>
        <Contact />
      </div>
    </div>
  );
}

export default App;
