interface ButtonProps {
  text: string;
  onClick: () => void;
  primary?: boolean;
}

export const Button = ({ onClick, primary = true, text }: ButtonProps) => {
  return (
    <button
      className={`w-full h-full text-black font-semibold rounded-lg my-1 ${
        primary
          ? "bg-violet-500  hover:bg-violet-600"
          : "border border-violet-500 text-white hover:opacity-70"
      } duration-300`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};
