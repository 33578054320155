import { SectionTitle } from "../components/SectionTitle";
import { SectionContainer } from "../components/SectionContainer";
import { MenuTab } from "../components/MenuTab";
import { Project, ProjectTypes } from "../types";
import { useState } from "react";
import { Card } from "../components/Card";
import { Link } from "react-router-dom";
import { useProjects } from "../hooks/useProjects";

const MobileDev = () => {
  return (
    <div className="px-2 py-2 text-center md:text-xl">
      <div className="flex justify-center h-28 md:h-40 my-8">
        <img
          src="/assets/images/MLG.png"
          className="bg-white rounded-md p-0.5"
        />
      </div>
      <div className="flex justify-center h-20">
        <a
          href="https://apps.apple.com/us/app/memory-lane-games/id1517193093"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img className="p-2 h-16 md:h-16" src="/assets/images/apple.svg" />
        </a>
        <a
          href="https://play.google.com/store/apps/details?id=com.memorylanegames.memorylanegameslite&hl=en&gl=US"
          target="_blank"
          rel="noopener noreferrer"
        >
          <img
            className="p-2 h-16 md:h-16"
            src="/assets/images/google-play-badge.png"
          />
        </a>
      </div>
      <h2>
        In my role as Software Engineer at Memory Lane Games, I had the pleasure
        of working within a talented development team using an exciting tech
        stack in MERN. Using MongoDB, Express.js, React and Node.js, we built a
        responsive, native Dementia Therapy App and support tooling such as a
        user facing admin panel and an extensive content management system, that
        accumulated over one hundred thousand downloads across platforms.
      </h2>
    </div>
  );
};
export const Projects = () => {
  const [currentProject, setCurrentProject] = useState<ProjectTypes>("game");
  const { projects } = useProjects(currentProject);

  return (
    <SectionContainer>
      <SectionTitle title="Projects" />
      <div className="flex w-full h-8 overflow-hidden justify-center my-8">
        <div className="flex justify-between w-11/12 md:w-2/6 px-2">
          <MenuTab
            header={"Web"}
            onClick={setCurrentProject}
            currentTab={currentProject}
          />
          <MenuTab
            header={"App"}
            onClick={setCurrentProject}
            currentTab={currentProject}
          />
          <MenuTab
            header={"Game"}
            onClick={setCurrentProject}
            currentTab={currentProject}
          />
          <MenuTab
            header={"Graphics"}
            onClick={setCurrentProject}
            currentTab={currentProject}
          />
        </div>
      </div>
      <div>
        <div
          className={`${
            currentProject === "app"
              ? null
              : "grid grid-cols-1 md:grid-cols-3 gap-8 p-2"
          }`}
        >
          {currentProject === "app" ? (
            <MobileDev />
          ) : (
            projects &&
            projects.map((section: Project) => {
              return (
                <Link to={`/showcase/${currentProject}/${section.id}`}>
                  <Card
                    title={section.title}
                    content={section.content}
                    image={section.image}
                  />
                </Link>
              );
            })
          )}
        </div>
      </div>
    </SectionContainer>
  );
};
