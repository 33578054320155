interface CardProps {
  title: string;
  content: string;
  image: string;
}

export const Card = ({ title, content, image }: CardProps) => {
  return (
    <div className="w-fit h-fit hover:scale-105 duration-200">
      <div className={`w-full h-44 md:h-48`}>
        <img
          src={image}
          className="m-auto rounded-md h-full w-full hover:opacity-20 hover:cursor-pointer"
        ></img>
      </div>
      <h2 className="text-lg font-semibold mt-2">{title}</h2>
      <p>{content}</p>
    </div>
  );
};
