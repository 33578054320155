import { useState } from "react";
import { Link } from "react-router-dom";

interface NavProps {
  bioRef?: any;
  projectRef?: any;
  contactRef?: any;
  showcase: boolean;
}

interface NavItemProps {
  name: string;
}

const NavItem = ({ name }: NavItemProps) => {
  return (
    <div className="hover:text-violet-400 hover:scale-105 hover:cursor-pointer">
      {name}
    </div>
  );
};

export const NavBar = ({
  bioRef,
  projectRef,
  contactRef,
  showcase = false,
}: NavProps) => {
  const [openMenu, setOpenMenu] = useState(false);

  return (
    <div className="w-full px-2 md:px-6">
      <div className="flex justify-between">
        <div>
          <a href="/" className="flex items-center py-5 px-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6 mr-2 text-violet-500"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M17.657 18.657A8 8 0 016.343 7.343S7 9 9 10c0-2 .5-5 2.986-7C14 5 16.09 5.777 17.656 7.343A7.975 7.975 0 0120 13a7.975 7.975 0 01-2.343 5.657z"
              />
              <path
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-width="2"
                d="M9.879 16.121A3 3 0 1012.015 11L11 14H9c0 .768.293 1.536.879 2.121z"
              />
            </svg>
            <Link to="/">
              <span className="font-semibold text-xl hover:cursor-pointer">
                Reece Carey
              </span>
            </Link>
          </a>
        </div>
        <div className="hidden font-semibold md:flex center-items space-x-12 py-5">
          {showcase ? (
            <Link to="/">
              <NavItem name="Home" />
            </Link>
          ) : (
            <>
              <div
                onClick={() => {
                  bioRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                <NavItem name="About" />
              </div>
              <div
                onClick={() => {
                  projectRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                <NavItem name="Projects" />
              </div>
              <div
                onClick={() => {
                  contactRef.current.scrollIntoView({
                    behavior: "smooth",
                    block: "start",
                  });
                }}
              >
                <NavItem name="Contact" />
              </div>
            </>
          )}
        </div>
        <div className="md:hidden flex items-center">
          <svg
            onClick={() => setOpenMenu(!openMenu)}
            xmlns="http://www.w3.org/2000/svg"
            className="h-6 w-6"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
          >
            <path
              stroke-linecap="round"
              stroke-linejoin="round"
              stroke-width="2"
              d="M4 6h16M4 10h16M4 14h16M4 18h16"
            />
          </svg>
        </div>
      </div>
      {openMenu ? (
        <div className="flex justify-center">
          <div className="block md:hidden text-lg text-center font-bold">
            {showcase ? (
              <Link to="/">
                <NavItem name="Home" />
              </Link>
            ) : (
              <>
                <div
                  className="py-1"
                  onClick={() => {
                    bioRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  <NavItem name="About" />
                </div>
                <div
                  className="py-1"
                  onClick={() => {
                    projectRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  <NavItem name="Projects" />
                </div>
                <div
                  className="py-1"
                  onClick={() => {
                    contactRef.current.scrollIntoView({
                      behavior: "smooth",
                      block: "start",
                    });
                  }}
                >
                  <NavItem name="Contact" />
                </div>
              </>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
};

export default NavBar;
