import { Link, useLoaderData } from "react-router-dom";
import { useProjects } from "../hooks/useProjects";
import { Project } from "../types";

import { SectionTitle } from "../components/SectionTitle";
import NavBar from "../sections/Navbar";
import { SectionContainer } from "../components/SectionContainer";
import { useRef } from "react";

interface ParamType {
  type: string;
  id: number;
}

export const Showcase = () => {
  const playerRef = useRef<any>(null);

  const { params } = useLoaderData() as any;
  const { type, id } = params as ParamType;

  const { projects } = useProjects(type as any);

  const selectedProject = projects?.find((project) => project.id == id);

  const filteredProjects = projects?.filter((project) => {
    return project.id != id;
  });
  const { innerWidth: width } = window;

  const isMobile = width <= 768 ? true : false;

  if (!selectedProject) {
    return (
      <div>
        <h1>No project associated</h1>
      </div>
    );
  }

  return (
    <div
      className="w-full h-full min-h-screen text-gray-300 bg-gray-800"
      ref={playerRef}
    >
      <NavBar showcase={true} />
      <SectionContainer>
        <SectionTitle title={"Showcase"} />
        <div className="h-full p-2 my-4 md:mx-4">
          <div className="flex flex-col justify-center my-4">
            {selectedProject.game &&
            (!isMobile || selectedProject.game.mobileSupport) ? (
              <iframe
                src={selectedProject.game.src}
                allowFullScreen={true}
                style={{
                  height: `${isMobile ? "450px" : "500px"}`,
                  aspectRatio: 1.6,
                }}
              />
            ) : (
              <>
                {selectedProject.video ? (
                  <iframe
                    src={selectedProject.video}
                    style={{
                      height: `${isMobile ? "225px" : "500px"}`,
                      aspectRatio: 1.6,
                    }}
                  />
                ) : (
                  <img
                    src={selectedProject.image}
                    className="rounded-lg mb-4 aspect-video"
                  />
                )}
              </>
            )}
            <div>
              <h1 className="text-2xl font-semibold pt-8">
                {selectedProject.title}
              </h1>
              <p className="py-2">{selectedProject.content}</p>
            </div>
          </div>
        </div>
        {filteredProjects && filteredProjects.length > 0 && (
          <h1 className="text-xl font-semibold py-2 text-center">
            More like this
          </h1>
        )}
        <div className="w-full grid grid-cols-1 md:grid-cols-3 m-auto mt-4">
          {filteredProjects?.map((project: Project) => {
            return (
              <Link to={`/showcase/${type}/${project.id}`}>
                <div className="flex flex-col justify-center items-center mt-4">
                  <img
                    src={project.image}
                    className="m-auto rounded-md h-44 w-5/6 hover:opacity-20 hover:cursor-pointer"
                    onClick={() => {
                      playerRef.current.scrollIntoView({
                        behavior: "smooth",
                        block: "start",
                      });
                    }}
                  />
                  <h1 className="text-sm font-semibold py-2">
                    {project.title}
                  </h1>
                </div>
              </Link>
            );
          })}
        </div>
      </SectionContainer>
    </div>
  );
};
