// @ts-ignore
import Lottie from "react-lottie";
import * as animationData from "../assets/lottie/computer-animation.json";
import { useState } from "react";
import { SectionTitle } from "../components/SectionTitle";
import { Bios } from "../types";
import { MenuTab } from "../components/MenuTab";

export const AboutMe = () => {
  const [activeBio, setActiveBio] = useState<Bios>("skills");

  const { innerWidth: width } = window;

  const lottieSize = width > 768 ? 300 : 200;

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  const BioMap = {
    skills: [
      "Javascipt",
      "CSS",
      "HTML",
      "Typescript",
      "React",
      "React Native",
      "Node.js",
      "MongoDB",
      "NoSQL",
      "Unity",
    ],
    education: [
      "De Montfort University",
      "BSc Honors Computer Games Programming",
      "Game Engine Architecture",
      "Game Engine Development",
      "Advanced Shader Programming",
      "Robotics and AI",
      "2023 Complete Web Development Bootcamp",
      "React - The Complete Guide 2023",
    ],
    experience: [
      "Three years of full stack development using React frameworks within the MERN tech stack",
      "Three years studying BSc Honors Computer Games Programming at De Montfort University, focusing on game development and C based languages, learning how to use Unity and the graphics pipeline",
      "Three years working at Newfield LTD as a Data Analyst",
    ],
  };
  return (
    <div className="px-4 py-4 justify-around w-full  max-w-7xl mx-auto">
      <SectionTitle title={"About Me"} />
      <div className="px-4 py-4 text-m text-center md:text-left md:flex md:flex-row md:text-xl text-gray-300">
        <div className="flex relative items-center py-4 px-12">
          <Lottie
            options={defaultOptions}
            height={lottieSize}
            width={lottieSize}
          />
        </div>
        <div className="my-8">
          <h2>
            A motivated and enthusiastic full stack developer with three years
            worth of experience across web and mobile platforms, while also
            having achieved a First Class Honors (BSc) in Computer Games
            Programming. I have experience working within the MERN tech stack,
            and I am also proficient in C languages and Unity. I am a
            passionate, self motivated learner who is always trying to expand
            his skill set.
          </h2>
          <div className="flex flex-row h-24 justify-between w-full md:w-72 py-8">
            <MenuTab
              header={"Skills"}
              onClick={() => setActiveBio("skills")}
              currentTab={activeBio}
            />
            <MenuTab
              header={"Education"}
              onClick={() => setActiveBio("education")}
              currentTab={activeBio}
            />
            <MenuTab
              header={"Experience"}
              onClick={() => setActiveBio("experience")}
              currentTab={activeBio}
            />
          </div>
          <div className="h-56 min-h-full md:h-72 text-left">
            <ul className="list-disc list-outside ml-6">
              {BioMap[activeBio].map((bio) => {
                return <li>{bio}</li>;
              })}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};
