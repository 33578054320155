import { useEffect, useState } from "react";
import { Project, ProjectTypes } from "../types";

const CardMap = {
  game: [
    {
      title: "Project Asteroid",
      content:
        "Bullet Hell style 2D space shooter demo, gaining inspiration from games such as 1943. Features saving score to local storage and scaling difficulty.",
      image: "/assets/images/ProjectAsteroid.JPG",
      game: {
        mobileSupport: false,
        src: "https://i.simmer.io/@Racara306/project-asteroid",
      },
      video: "https://www.youtube.com/embed/z5aB-795yzQ",
      id: 1,
    },
    {
      title: "Project: Assault",
      content:
        "Tech demo showcasing terrain building and the Unity Timeline feature to create a space themed, on-rails shooter.",
      image: "/assets/images/ProjectAssault.jpg",
      game: {
        mobileSupport: false,
        src: "https://i.simmer.io/@Racara306/project-assault",
      },
      video: "https://www.youtube.com/embed/8u0019NFMKw",
      id: 2,
    },
    {
      title: "Project: Boost",
      content:
        "A simple, work in progress, rocket physics simulator using primative shapes and basic particle effects to create everything within the game world.",
      image: "/assets/images//ProjectBoost.jpg",
      game: {
        mobileSupport: false,
        src: "https://i.simmer.io/@Racara306/project-boost",
      },
      video: "https://www.youtube.com/embed/HYAS5KU-uJY",
      id: 3,
    },
    {
      title: "Firewall VR",
      content:
        "Firewall VR is a defence style VR shooter with a focus on VR accessibility and also reactive artificial intelligence.",
      image: "/assets/images/FirewallVR.jpg",
      game: null,
      video: "https://www.youtube.com/embed/qSReM3vLsoI",
      id: 4,
    },
    {
      title: "Tool Britannia",
      content:
        "Tool Britannia is my Global Games Jam 2020 submission, created within a talented team of programmers and artists.",
      image: "/assets/images/ToolBritannia.jpg",
      game: null,
      video: "https://www.youtube.com/embed/aZppuCvAYrM",
      id: 5,
    },
    {
      title: "2D Physics Simulator",
      content:
        "Top down racing simulator used to demonstrate object-oriented programming using the BOX2D physics engine.",
      image: "/assets/images/2DSimulator.jpg",
      game: null,
      video: "https://www.youtube.com/embed/NM3VK43Y7aU",
      id: 6,
    },
    {
      title: "Rollerball",
      content:
        "RollerBall is my first excecutable mobile game, made in second year of university.",
      image: "/assets/images/Rollerball.jpg",
      game: null,
      video: "https://www.youtube.com/embed/F1MGw67u4qw",
      id: 7,
    },
    {
      title: "Frogger",
      content: "Frogger was my final project in my first year of university.",
      image: "/assets/images/Frogger.jpg",
      game: null,
      video: "https://www.youtube.com/embed/yZZtO_lBFWU",
      id: 8,
    },
  ],
  graphics: [
    {
      title: "Advanced Shader Programming",
      content:
        "Tech demo to showcase randomly generated terrain and procedurally rendered fog",
      image: "/assets/images/AdvancedShaders.jpg",
      game: null,
      video: "https://www.youtube.com/embed/ZVnFEgb_wFE",
      id: 1,
    },
    {
      title: "Shader Programming",
      content:
        "Tech demo showcasing different effects each stage of the render pipeline has on objects with dynamic lighting",
      image: "/assets/images/Shaders.jpg",
      game: null,
      video: "https://www.youtube.com/embed/yAMq0hMgKLE?si=om4igoFsxu_cjU_n",
      id: 2,
    },
    {
      title: "Game Engine Architecture",
      content:
        "Showcase of a LUA scripted robot used to map out a room and avoid objects based on set parameters",
      image: "/assets/images/EngineArchitecture.jpg",
      game: null,
      video: "https://www.youtube.com/embed/h8jWQn6sdIo",
      id: 3,
    },
    {
      title: "Mobile Robotics",
      content: "Frogger was my final project in my first year of university.",
      image: "/assets/images/MobileRobotics.jpg",
      game: null,
      video: "https://www.youtube.com/embed/VQH2eF1HLVY",
      id: 4,
    },
  ],
  app: [],
  web: [
    {
      title: "Memory Lane Games Web client",
      content:
        "A lightweight web based SPA used as a marketing tool to allow users to try a game related to the Memory Lane Games App",
      image: "/assets/images/mlg-white.png",
      game: {
        mobileSupport: true,
        src: "https://play.memorylanegames.com/game/YUIUn0gYUk1T-LEcBA",
      },
      id: 1,
    },
    {
      title: "Legacy Website",
      content: `An older version of a similar style portfolio used to learn the fundamentals of Next JS and SSR`,
      image: "/assets/images/legacy-site.png",
      game: {
        mobileSupport: false,
        src: "https://legacy.reececarey.dev",
      },
      id: 2,
    },
  ],
};

export const useProjects = (projectType: ProjectTypes) => {
  const [projects, setProject] = useState<Project[]>();

  useEffect(() => {
    const chosenProjects = CardMap[projectType];
    setProject(chosenProjects);
  }, [projectType]);

  return { projects };
};
