import { useEffect, useState } from "react";

interface NameTypes {
  names: string[];
}
export const useRemoveLastCharacter = ({ names }: NameTypes) => {
  const [modifiedName, setModifiedName] = useState<string>(names[0]);
  const [index, setIndex] = useState(0);

  const [deleting, setDeleting] = useState(true);

  let fullName = names[index];
  let changedText = "";

  const rewriteName = () => {
    if (deleting) {
      changedText = fullName.substring(0, modifiedName.length - 1);
      setModifiedName(changedText);
    } else {
      changedText = fullName.substring(0, modifiedName.length + 1);
      setModifiedName(changedText);
    }

    if (!deleting && fullName === modifiedName) {
      setDeleting(true);
    } else if (deleting && modifiedName === "") {
      if (index < names.length - 1) {
        setIndex((prevState) => prevState + 1);
      } else {
        setIndex(0);
      }
      setDeleting(false);
    }
  };

  useEffect(() => {
    const intervalId = setInterval(() => {
      rewriteName();
    }, 150);

    return () => {
      clearInterval(intervalId); // Cleanup the interval on component unmount
    };
  }, [modifiedName, deleting]);

  return { modifiedName };
};
