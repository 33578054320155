interface TitleProps {
  title: string;
}

export const SectionTitle = ({ title }: TitleProps) => {
  return (
    <div className="flex justify-evenly text-center items-center">
      <div className="border-t-4 w-3/5 md:w-full border-violet-500" />
      <h1 className="w-4/5 md:w-3/5 px-8 text-2xl lg:text-3xl text-gray-300 font-bold">
        {title}
      </h1>
      <div className="border-t-4 w-3/5 md:w-full border-violet-500" />
    </div>
  );
};
