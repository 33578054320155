import { PropsWithChildren } from "react";
import { Bios, ProjectTypes } from "../types";
import { capitaliseFirstLetter } from "../utils/capitaliseFirstLetter";

interface MenuTabProps {
  header: string;
  currentTab: Bios | ProjectTypes;
  onClick: (name: any) => void;
}

export const MenuTab = ({
  header,
  onClick,
  currentTab,
}: PropsWithChildren<MenuTabProps>) => {
  const key = capitaliseFirstLetter(header);

  return (
    <button
      className={`text-xl hover:scale-110 duration-300 ${
        currentTab === key
          ? "font-semibold border-b-4 border-violet-500"
          : "text-gray-400"
      }`}
      onClick={() => onClick(key)}
    >
      {header}
    </button>
  );
};
