import { useRouteError } from "react-router-dom";

interface ErrorProps {
  statusText: string;
  message: string;
  data: string;
  status: string;
}

export const ErrorPage = () => {
  const error = useRouteError();

  const { status, statusText, data } = error as ErrorProps;

  return (
    <div className="flex flex-col  h-screen justify-center items-center">
      <h1>
        {status} {statusText}
      </h1>
      <h1>{data}</h1>
    </div>
  );
};
