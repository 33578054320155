import { useRef, useState } from "react";
import { SectionContainer } from "../components/SectionContainer";
import { AiOutlineLinkedin, AiOutlineGithub } from "react-icons/ai";
import emailjs from "@emailjs/browser";

const ContactSection = () => {
  const formRef = useRef(null);

  const [disableButton, setDisableButton] = useState(false);

  const { REACT_APP_SERVICE_ID, REACT_APP_TEMPLATE_ID, REACT_APP_PUBLIC_KEY } =
    process.env;

  const sendEmail = (e: any) => {
    e.preventDefault(); // Add this line to prevent default form submission behavior
    setDisableButton(true);

    if (
      !formRef.current ||
      !REACT_APP_SERVICE_ID ||
      !REACT_APP_TEMPLATE_ID ||
      !REACT_APP_PUBLIC_KEY
    ) {
      return;
    }

    emailjs
      .sendForm(
        REACT_APP_SERVICE_ID,
        REACT_APP_TEMPLATE_ID,
        formRef.current,
        REACT_APP_PUBLIC_KEY
      )
      .then(
        (result) => {
          e.target.reset();
          alert("Submission successful");
          setDisableButton(false);
          console.log(result.text);
        },
        (error) => {
          alert("Submission failed, please try again later");
          setDisableButton(false);
          console.log(error.text);
        }
      );
  };

  return (
    <form ref={formRef} onSubmit={sendEmail}>
      <div className="flex flex-col text-left">
        <h1 className="font-semibold text-lg">Name</h1>
        <input
          className="mb-2 p-1 rounded-lg text-slate-950"
          type="text"
          name="user_name"
          placeholder="Please enter your name"
          required
          autoComplete="off"
        />
        <h1 className="font-semibold text-lg">Your email</h1>
        <input
          className="mb-2 p-1 rounded-lg text-slate-950"
          name="user_email"
          type="text"
          placeholder="Enter your email"
          required
        />
        <h1 className="font-semibold text-lg">Subject</h1>
        <input
          className="mb-2 p-1 rounded-lg text-slate-950"
          name="subject"
          type="text"
          placeholder="Email subject"
          required
          autoComplete="off"
        />
        <h1 className="font-semibold text-lg">Message</h1>
        <input
          className="mb-2 p-1 rounded-lg text-slate-950"
          name="message"
          type="text"
          placeholder="Your message"
          required
          autoComplete="off"
        />
        <div className="h-8">
          <input
            className={`w-full h-full text-black font-semibold rounded-lg my-1 bg-violet-500  hover:bg-violet-600 duration-300`}
            type="submit"
            value="Send"
            disabled={disableButton}
          />
        </div>
      </div>
    </form>
  );
};

export const Contact = () => {
  return (
    <SectionContainer>
      <div className="flex flex-col items-center justify-end py-32 md:items-start md:flex-row text-center md:text-left">
        <div>
          <h1 className="text-2xl font-semibold py-2">Contact me</h1>
          <p className="text-l py-4 md:w-4/6">
            Interested in learning more about my work? Contact me on LinkedIn or
            shoot me a message using the this form!
          </p>
          <div className="flex flex-row py-4 md:py-2">
            <a
              href="https://www.linkedin.com/in/reece-carey-b4b025199/"
              target="_blank"
            >
              <AiOutlineLinkedin className="h-12 w-12" />
            </a>
            <a href="https://github.com/Racara306" target="_blank">
              <AiOutlineGithub className="h-12 w-12" />
            </a>
          </div>
        </div>

        <div className="w-full md:w-1/2">
          <ContactSection />
        </div>
      </div>
    </SectionContainer>
  );
};
